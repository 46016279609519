import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import smartDeviceStoreModule from '../smartDeviceStoreModule';

export default function useSmartDeviceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'smartDevice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, smartDeviceStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref({ ...JSON.parse(JSON.stringify(props.item)), provider: 'vconnex' });
  const resetItemLocal = () => {
    itemLocal.value = { ...JSON.parse(JSON.stringify(props.item)), provider: 'vconnex' };
    isSubmitting.value = false;
  };
  const resetModal = () => {
    resetItemLocal();
  };

  const fetchTuyaAccount = () => {
    store.dispatch('smartDevice/getTuyaAccount').then(response => {

    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const syncTuyaDevices = () => {
    store.dispatch('smartDevice/syncTuyaDevices').then(response => {

    }).catch(error => {
      toastification.showToastError(error);
    });
  };

  const changeProvider = val => {
    itemLocal.value = { ...itemLocal.value, provider: val };
    if (val === 'tuya') {
      fetchTuyaAccount();
    }
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      deviceId: itemLocal.value.deviceId,
      type: itemLocal.value.typeObject.value,

    };

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('smartDevice/updateSmartDevice', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('smartDevice/createSmartDevice', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const fetchVConnexAuthorizationUrl = () => {
    store
      .dispatch('smartDevice/fetchVConnexAuthorizationUrl')
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    syncTuyaDevices,
    fetchTuyaAccount,
    changeProvider,
    resetItemLocal,
    resetModal,
    onSubmit,
    fetchVConnexAuthorizationUrl,
  };
}
