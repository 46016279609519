<template>
  <b-modal
    id="modal-link-smart-device"
    ref="refModalLinkDevice"
    title="Liên kết thiết bị"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <div>

      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>

            <b-col

              cols="6"
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :default-value="itemLocal.apartment"
                :filter-active="true"
                label="Tòa nhà"
              />
            </b-col>
            <b-col
              cols="6"
            >
              <select-room
                v-model="itemLocal.room"
                :default-value="itemLocal.room"
                :apartment="itemLocal.apartment"
                :filter-active="true"
                label="Phòng"
              />
            </b-col>
            <b-col>
              <validation-provider
                #default="validationContext"
                name="Công tơ"
                rules="required"
              >
                <select-meter
                  v-model="itemLocal.meter"
                  label="Công tơ"
                  required
                  :default-value="itemLocal.meter"
                  :error="validationContext.errors[0]"
                  :apartment="itemLocal.apartment"
                  :room="itemLocal.room"
                />

              </validation-provider>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </div>

  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import SelectMeter from '@/views/components/SelectMeter.vue';
import useLinkDeviceModal from './useLinkDeviceModal';

export default {
  components: {
    BModal,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    SelectApartment,
    SelectRoom,
    SelectMeter,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalLinkDevice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      fetchVConnexAuthorizationUrl,
      onSubmit,
    } = useLinkDeviceModal(props, emit, refFormObserver);

    return {
      refModalLinkDevice,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,
      fetchVConnexAuthorizationUrl,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
