<template>
  <b-modal
    id="modal-smart-device"
    ref="refModal"
    title="Thiết bị thông minh"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    scrollable
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >

    <div>
      <b-row>
        <b-col cols="6">
          <b-card
            no-body
            :class="`shadow-none border ${itemLocal.provider === 'vconnex' ? 'bg-light-primary' : 'bg-light-secondary'} `"
            @click="changeProvider('vconnex')"
          >
            <b-card-header>
              <b-card-title>VConnex</b-card-title>
              <b-form-radio
                v-model="itemLocal.provider"
                name="provider"
                value="vconnex"
                class="custom-control-primary"
              />
            </b-card-header>
          </b-card>

        </b-col>
        <b-col cols="6">
          <b-card
            no-body
            :class="`shadow-none border ${itemLocal.provider === 'tuya' ? 'bg-light-primary' : 'bg-light-secondary'} `"
            @click="changeProvider('tuya')"
          >
            <b-card-header>
              <b-card-title>Tuya</b-card-title>
              <b-form-radio
                v-model="itemLocal.provider"
                name="provider"
                value="tuya"
                class="custom-control-danger"
              />
            </b-card-header>
          </b-card>

        </b-col>
        <!-- <b-col cols="4">
          <b-card
            no-body
            :class="`shadow-none border ${itemLocal.provider === 'ricetech'? 'bg-light-primary' : 'bg-light-secondary'} `"
            @click="changeProvider('ricetech')"
          >
            <b-card-header>
              <b-card-title>Rice Tech</b-card-title>
              <b-form-radio
                v-model="itemLocal.provider"
                name="provider"
                value="ricetech"
                class="custom-control-danger"
              />
            </b-card-header>
          </b-card>

        </b-col> -->

      </b-row>
      <b-row v-if="itemLocal.provider === 'vconnex'">
        <b-col
          cols="12"
          class="mt-1"
        >
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span class="ml-25">Để thêm thiết bị của VConnex, bạn cần <b-link
                class="text-danger"
                @click="fetchVConnexAuthorizationUrl"
              >Đăng nhập VConnex</b-link> để cấp quyền và đồng bộ thiết bị</span>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <b-row v-if="itemLocal.provider === 'tuya'">
        <b-col
          cols="12"
        >
          <b-alert
            show
            variant="primary"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="StarIcon"
              />
              <span class="ml-25">Để thêm thiết bị của Tuya, bạn cần thực hiện các bước sau:</span>
            </div>
          </b-alert>
        </b-col>
        <b-col>
          <b-list-group>
            <b-list-group-item>Bước 1: Tải ứng dụng <strong>Smart Industry</strong> về điện thoại của bạn. Quét mã QRCode bên dưới để tải về:
              <br><br><br>
              <b-img
                :src="require('@/assets/images/qrcode/smart_industry.jpeg')"
                width="120px"
                height="120px"
              /></b-list-group-item>
            <b-list-group-item>Bước 2: Sử dụng tài khoản sau để đăng nhập và thêm thiết bị:
              <br><br>- Project: Quét mã QRCode bên dưới để chọn Project
              <br><br>
              <b-img
                :src="require('@/assets/images/qrcode/tingee_ocb.png')"
                width="120px"
                height="120px"
              />
              <br><br>- Email: <strong>0868987355@resident.vn</strong>
              <br><br>- Mật khẩu: <strong>Abcd@1234</strong></b-list-group-item>
            <b-list-group-item>
              Bước 3: Để tiến hành thêm thiết bị, bạn thao tác như sau:
              <br><br>- Ấn nút reset trên Công tơ Tuya để đưa về trạng thái kết nối ban đầu
              <br><br>- Chọn mục Add device -> Năng lượng
              <br><br>- Chọn mục Đèn nhấp nháy nhanh
              <br><br>- Để điện thoại gần thiết bị để có thể tìm thấy
              <br><br>- Sau khi thêm thiết bị thành công, bạn có thể đặt tên thiết bị để dể quản lý
            </b-list-group-item>
            <b-list-group-item>
              Bước 4: Sau khi thêm tất cả thiết bị, vui lòng ấn nút Đồng bộ bên dưới để Resident đồng bộ thiết bị
              <br>
              <b-button
                variant="primary"
                class="mt-1"
                @click="syncTuyaDevices"
              >
                Đồng bộ thiết bị
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>

      <!-- <b-row v-if="itemLocal.provider === 'ricetech'">
        <b-col>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <validation-provider
                #default="validationContext"
                name="type"
                rules="required"
              >
                <select-smart-device-type
                  v-model="itemLocal.typeObject"
                  :default-value="itemLocal.typeObject"
                  label="Loại thiết bị"
                  :state="getValidationState(validationContext)"
                  required
                  :error="validationContext.errors[0]"
                />
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Tên thiết bị thông minh"
                  class="mt-2"
                >
                  <template
                    v-slot:label
                  >
                    Tên thiết bị thông minh
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="name"
                    v-model="itemLocal.name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="validationContext"
                name="deviceId"
                rules="required"
              >
                <b-form-group
                  label="Mã thiết bị thông minh"
                  class="mt-2"
                >
                  <template
                    v-slot:label
                  >
                    Mã thiết bị thông minh
                    <span
                      class="text-danger"
                    >
                      (*)
                    </span>
                  </template>
                  <b-form-input
                    id="deviceId"
                    v-model="itemLocal.deviceId"
                    :state="getValidationState(validationContext)"
                    trim
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

            </b-form>
          </validation-observer>
        </b-col>

      </b-row> -->

    </div>

  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BAlert,
  BLink,
  BCardHeader,
  BCard,
  BCardTitle,
  BFormRadio,
  BListGroup,
  BListGroupItem,
  BImg,
  BButton,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useSmartDeviceModal from './useSmartDeviceModal';

export default {
  components: {
    BModal,
    BImg,
    BListGroup,
    BListGroupItem,
    BRow,
    BLink,
    BCol,
    BAlert,
    BCardHeader,
    BCard,
    BCardTitle,
    BFormRadio,
    BButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      syncTuyaDevices,
      resetItemLocal,
      resetModal,
      fetchVConnexAuthorizationUrl,
      onSubmit,
      changeProvider,
    } = useSmartDeviceModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      syncTuyaDevices,
      resetItemLocal,
      resetModal,
      onSubmit,
      fetchVConnexAuthorizationUrl,
      changeProvider,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
