import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import smartDeviceStoreModule from '../smartDeviceStoreModule';

export default function useLinkDeviceModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'smartDevice';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, smartDeviceStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModalLinkDevice = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      meterId: itemLocal.value.meter.id,
      smartDeviceId: itemLocal.value.id,
    };

    isSubmitting.value = true;

    store
      .dispatch('smartDevice/linkSmartDevice', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalLinkDevice.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  const fetchVConnexAuthorizationUrl = () => {
    store
      .dispatch('smartDevice/fetchVConnexAuthorizationUrl')
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  return {
    refModalLinkDevice,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    fetchVConnexAuthorizationUrl,
  };
}
