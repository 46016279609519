<template>
  <b-form-group
    :label="label"
  >
    <template
      v-if="label"
      v-slot:label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >
        (*)
      </span>
    </template>
    <v-select
      id="type"
      v-model="type"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :options="options"
      placeholder="Loại thiết bị"
      @input="valueChanged"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        Không có dữ liệu
      </template>
    </v-select>
    <small class="text-danger">{{ error }}</small>
  </b-form-group>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      type: this.defaultValue,
      options: [
        { title: 'Đồng hồ điện', value: 'electric' },
        { title: 'Đồng hồ nước', value: 'water' },
      ],
    };
  },
  methods: {
    valueChanged() {
      this.$emit('input', this.type);
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@/assets/scss/vue-select.scss';
</style>
